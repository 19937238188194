<script>
    import { required, minLength, numeric } from "vuelidate/lib/validators";
    import Teams from '@/services/Teams'
    import validationMessages from '@/components/validations'
    import vue2Dropzone from "vue2-dropzone";

    import "vue2-dropzone/dist/vue2Dropzone.min.css";
    import "vue-multiselect/dist/vue-multiselect.min.css";


    export default {
        components:{
            vueDropzone: vue2Dropzone,
            validationMessages
        },
        props: {
            team: {
                type: String,
                default: null
            },
            squad_func:{

            }
        },
        data() {
            return {
                additional_squad_images: process.env.VUE_APP_ADDITIONAL_SQUAD_IMAGES,
                csrf_token: localStorage.getItem('csrf_token'),
                first_name: '',
                last_name: '',
                shirt_number: '',
                player_function: '',
                order: '',
                img: '',
                file: '',
                file_additional: [],
                twitter: '',
                instagram: '',
                submitted: false,
                showModal: false,
                tryingToEdit: false,
                selected_func: null,
                dropzoneOptions: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 150,
                    thumbnailMethod: 'contain',
                    maxFilesize: 2,
                    acceptedFiles:'image/*'
                },
                dropzoneOptions_primary: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 200,
                    thumbnailMethod: 'contain',
                    maxFilesize: 2,
                    acceptedFiles:'image/*',
                    maxFiles: 2,
                },
            };
        },
        validations: {
            first_name: {
                required,
                minLength: minLength(2)
            },
            last_name: {
                required,
                minLength: minLength(2)
            },
            player_function:{
              required
            },
            order: {
                numeric,
            },
        },
        methods: {
            refreshData() {
                this.$emit('onRefresh') //event from parent
            },
            async addSquadPlayer() {
                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {
                    try {

                        let formData = new FormData()
                        formData.append('type', this.team)
                        formData.append('team', process.env.VUE_APP_TENANT)
                        formData.append('source', 'Dashboard')
                        formData.append('first_name', this.first_name)
                        formData.append('last_name', this.last_name)
                        formData.append('function', this.player_function)
//                        formData.append('file', this.file)
                        if(this.file){
                            formData.append('file', this.file)
                        }
                        formData.append('shirt_number', this.shirt_number)
                        formData.append('instagram', this.instagram)
                        formData.append('twitter', this.twitter)
                        formData.append('csrf_token', this.csrf_token)

                        if(!this.file){
                            formData.append('img', this.img)
                        }

                        if(this.file_additional){
                            this.file_additional.forEach((file) => {
                                formData.append('file_additional[]', file);
                            });
                        }

                        await Teams.addSquadPlayer(formData)
                        .then((response) => {
                            this.successmsg('Change has been saved');
                            this.closeModal();
                        }).catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "";
                            this.failedmsg(this.error)
                        }).finally(() => {
                            this.refreshData()
                            this.tryingToEdit = false;
                        })

                    } catch (error) {
                         this.error = error.response.data.error ? error.response.data.error : "";
                         this.failedmsg(this.error)
                    }
                }
                this.tryingToEdit = false;
            },

            selectFile(files){
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_primary.getQueuedFiles();

                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only have one primary image')

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_primary.removeFile(value)
                        });
                    }

                    this.file = queuedFiles[0];

                 }, 250);
            },

            selectFile_additional(file){

              setTimeout(() => {
                   let queuedFiles2 = this.$refs.myVueDropzone.getQueuedFiles();
                                this.file_additional = queuedFiles2
               }, 250);

            },

            closeModal() {
                this.showModal = false;
            },

            resetProps(){
                this.first_name= '';
                this.last_name= '';
                this.shirt_number= '';
                this.player_function= '';
                this.order= '';
                this.img= '';
                this.file= '';
                this.twitter= '';
                this.instagram= '';
                this.selected_func= null;
                this.tryingToEdit = false;
                this.submitted = false;
            },
        }
    };
</script>


<template>
    <b-modal v-model="showModal" @hidden="resetProps" id="squad_add" title="Add Squad Player" title-class="font-18" centered>

        <form @submit.prevent="addSquadPlayer">
            <div class="row">

                <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3">
                    <b-tab title="Profile" active title-item-class="mb-2">
                        <b-card-text>
                            <b-form-group label="Firstname" label-for="formrow-firstname-input" class="mb-3">
                                <b-form-input
                                        v-model.trim="first_name"
                                        type="text"
                                        id="firstname"
                                        aria-describedby="firstname-feedback"
                                        :class="{
                                          'is-invalid': submitted && $v.first_name.$error,
                                        }"
                                >
                                </b-form-input>
                                <validationMessages v-if="submitted" :fieldName="'First Name'" :validationName="$v.first_name"></validationMessages>
                            </b-form-group>

                            <b-form-group label="Lastname" label-for="formrow-lastname-input" class="mb-3">
                                <b-form-input
                                        v-model.trim="last_name"
                                        type="text"
                                        id="lastname"
                                        aria-describedby="lastname-feedback"
                                        :class="{
                                          'is-invalid': submitted && $v.last_name.$error,
                                        }"
                                >
                                </b-form-input>
                                <validationMessages v-if="submitted" :fieldName="'Last Name'" :validationName="$v.last_name"></validationMessages>
                            </b-form-group>

                            <b-form-group label="Function" label-for="formrow-function-input" class="mb-3">
                                <select v-model="player_function" class="form-select"
                                        :class="{
                                          'is-invalid': submitted && $v.player_function.$error,
                                        }"
                                >
                                    <option v-for="sfunc in squad_func" :key="sfunc.function_name" :value="sfunc.function_title">{{sfunc.function_title}}</option>
                                </select>
                                <validationMessages v-if="submitted" :fieldName="'Player Function'" :validationName="$v.player_function"></validationMessages>
                            </b-form-group>

                            <b-form-group label="Shirt Number" label-for="formrow-shirt_number-input" class="mb-3">
                                <b-form-input
                                        v-model.trim="shirt_number"
                                        type="text"
                                        id="shirt_number"
                                        aria-describedby="shirt_number-feedback"
                                >
                                </b-form-input>
                            </b-form-group>

                            <b-form-group label="Instagram" label-for="formrow-instagram-input" class="mb-3">
                                <b-form-input
                                        v-model.trim="instagram"
                                        type="text"
                                        id="instagram"
                                        aria-describedby="twitter-feedback"
                                >
                                </b-form-input>
                            </b-form-group>

                            <b-form-group label="Twitter" label-for="formrow-twitter-input" class="mb-3">
                                <b-form-input
                                        v-model.trim="twitter"
                                        type="text"
                                        id="twitter"
                                        aria-describedby="twitter-feedback"
                                >
                                </b-form-input>
                            </b-form-group>
                            <input type="hidden" name="csrf_token" v-model="csrf_token">
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Images" title-item-class="mb-2">
                        <b-card-text class="text-center">

                            <div class="row mb-3">
                                <div class="col-12">
                                    <h5 class="text-center">Primary Image</h5>

                                    <vue-dropzone
                                            id="dropzone_primary"
                                            ref="myVueDropzone_primary"
                                            :use-custom-slot="true"
                                            :options="dropzoneOptions_primary"
                                            @vdropzone-files-added="selectFile"
                                            no-progress-bar
                                    >
                                        <div class="dropzone-custom-content">
                                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                            <h4>Drop file here or click to upload.</h4>
                                        </div>
                                    </vue-dropzone>

                                </div>
                            </div>
                            <div class="row mt-3" v-if="additional_squad_images == 'true'">

                                <div class="col-12 ">
                                    <h5>Additional Images</h5>
                                    <vue-dropzone
                                            id="dropzone"
                                            ref="myVueDropzone"
                                            :use-custom-slot="true"
                                            :options="dropzoneOptions"
                                            @vdropzone-files-added="selectFile_additional"
                                            no-progress-bar
                                    >
                                        <div class="dropzone-custom-content">
                                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                                            <h4>Drop files here or click to upload.</h4>
                                        </div>
                                    </vue-dropzone>
                                </div>
                            </div>

                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </div>

        </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addSquadPlayer" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
</template>